import Modal from "../../../UI/Modal";
import {Link} from "react-router-dom";
import CloseButtonModal from "../../../UI/CloseButtonModal";
import Axios from "axios";
import {useEffect, useState} from "react";
import "./BloemToevoegen.css"

const BloemToevoegen = props => {
    const [naamValue, setNaamValue] = useState("");
    const [descriptieValue, setDescriptieValue] = useState("")
    const [herkomst, setHerkomst] = useState("")
    const [image, setImage] = useState("")

    function handleImage(e) {
        console.log(e.target.file)
        setImage(e.target.files[0])
    }
    const naamValueHandler = e => {
        setNaamValue(e.target.value)
    }
    const descriptieHandler = e => {
        setDescriptieValue(e.target.value)
    }
    const herkomstHandler = e => {
        setHerkomst(e.target.value)
    }
    const nieuweBloem = e => {
        e.preventDefault()

        Axios.post("/nieuweBloem", {
            naam: naamValue,
            descriptie: descriptieValue,
            herkomst: herkomst,
            image: image
        })

        window.open("/bloemen", "_self")
    };

    return (
        <Modal>
            <div>
                <Link to={'/bloemen'}>
                    <CloseButtonModal/>
                </Link>
            </div>
            <div className={"popup-bloem"}>
                <div className={"popup-bloem__form"}>
                    <form>
                        <div className={"popup-bloem__form-set"}>
                            <label>Naam:</label>
                            <input type={"text"} name={"naam"} value={naamValue} onChange={naamValueHandler}/>
                        </div>

                        <div className={"popup-bloem__form-set"}>
                            <label>informatie:</label>
                            <input type={"text"} name={"description"} value={descriptieValue}
                                   onChange={descriptieHandler}/>
                        </div>

                        <div className={"popup-bloem__form-set"}>
                            <label>Herkomst:</label>
                            <input type={"text"} name={"herkomst"} value={herkomst} onChange={herkomstHandler}/>
                        </div>

                        <div className={"popup-bloem__form-set"}>
                            <input type={"file"} name={'file'} onChange={handleImage}/>
                        </div>
                    </form>

                    <button className={"button__bloem-aanmaken"}
                            onClick={nieuweBloem}
                    >Bloem Aanmaken
                    </button>
                </div>
            </div>
        </Modal>
    )
}
export default BloemToevoegen