import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile} from "@fortawesome/free-solid-svg-icons";

const HistoryItem = props => {
    return <li value={props.id} className={"list-costumer"}>
        <p><FontAwesomeIcon icon={faFile} size="lg" style={{color: "#000000",}} />{props.id}</p>
        <p>{props.name}</p>
        <p>{props.bedrijf}</p>
    </li>
}
export default HistoryItem