import "./opkomendeAfspraken.css"
const OpkomendeAfspraken = props => {
    const overHoeveelDagen = () => {
        let aantalDagen;
        console.log(props.datum)
        aantalDagen = 100 - 10
        return parseInt(props.date) - parseInt(Date.now())
    }
    return (
        <li className={"list-appointments"}>
            <p>Naam: {props.name}</p>
            <p>Locatie: {props.location}</p>
            <p>Datum: {props.datum}</p>
        </li>
    )
}
export default OpkomendeAfspraken