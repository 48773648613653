import {useState} from "react";

const KlantenSelect = props => {
    const [id, setId] = useState("")
    const handleSelectChange = () => {
        console.log("Handling select change")
        const klantData = {
            id: props.id,
            naam: props.naam,
            adres: props.adres,
            woonplaats: props.stad,
            postcode: props.postcode,
            kvk: props.kvk
        };
        props.handleKlantSelect(klantData)
    }

    return (
        <option
            value={props.id}
            onChange={handleSelectChange}
        >{props.name}
        </option>
    )
}

export default KlantenSelect