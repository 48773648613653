import React, {useEffect, useState} from "react";
import "./home.css"
import OpkomendeAfspraken from "./opkomendeAfspraken/opkomendeAfspraken";
import AfsprakenList from "../../UI/afsprakenList"

const Home = (props) => {
    const [afsprakenLijstje, setAfsprakenLijstje] = useState([])

    useEffect(() => {
        fetch("/getAfspraken").then(res => {
            return res.json();
        })
            .then(data => {
                console.log(data)
                setAfsprakenLijstje(data)
            })
    }, [])
    const afsprakenLijst = afsprakenLijstje.map(appointment =>
        <OpkomendeAfspraken
            id={appointment._id}
            key={appointment._id}
            name={appointment.relatie}
            location={appointment.stad}
            datum={appointment.datum}
        />
    )
    // function afsprakenFunctie() {
    //     let afspraken;
    //     if (afsprakenLijst.length > 0) {
    //         afspraken = afsprakenLijst
    //
    //     } else {
    //         afspraken = <p>Er zijn nog geen toekomstige afspraken</p>
    //     }
    //     console.log(afspraken)
    //     return afspraken
    //
    // }

return (
    <div className={"base-container"}>
        <div className={"greeting-home"}>
            {greeting() + " Majel!"}
        </div>


        <div className={"home-opkomende-afspraken"}>
            <div className={"h2-opkomende-afspraken"}>
                <h2 >Opkomende afspraken</h2>
            </div>
            <AfsprakenList >
                {afsprakenLijst}
            </AfsprakenList>

        </div>
        <div>
            <button className={"home-afspraak-button"} onClick={props.onShowPopupAfspraak}>Maak een afspraak!</button>
        </div>
    </div>
)
}

function greeting(message) {
    let time = new Date(),
        hour = time.getHours();

    if (hour <= 12) {
        message = "Goedemorgen "
    } else if (hour > 12 && hour < 18) {
        message = "Goedemiddag "
    } else {
        message = "Goedenavond"
    }

    return message
}




export default Home