import React, {useState} from "react";
import "./login.css";
import logo from "../../images/Logo Gerard.png"
import App from "../../App";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const LoginPage = props => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loginError, setLoginError] = useState('')

    const usernameHandler = e => {
        setUsername(e.target.value)
    }
    const passwordHandler = e => {
        setPassword(e.target.value)
    }

    const loginButton = async (e) => {
        try {
            const response = await fetch('/inloggen', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                console.log('Authentication succesfull')
                const data = await response.json();
                console.log('Response data:', data)

                if (data.success) {
                    console.log("succes")
                    Cookies.set('sessionToken', data.sessionToken, { expires: 1, path: '/'})
                    console.log(data.sessionToken)
                    navigate("/")
                    props.onLogin()
                } else {
                    console.log('failure')
                }
            } else {
                const errorData = await response.json();
                setLoginError(errorData.message || 'Authentication failed')
            }
        } catch (err) {
            console.log('Error during login:', err);
            setLoginError('An error occurred during login.')
        }
    }
    return (
        <div className={"login-container"}>
            <div className={"image-container"}>
                <img alt={"logo"} src={logo}/>
            </div>

            <div className={"container-input"}>

                <div className={"input-set-login"}>
                    <label>Gebruikersnaam:</label>
                    <input type={"text"} id={"username-login"} value={username} onChange={usernameHandler}/>
                </div>

                <div className={"input-set-login"}>
                    <label>Wachtwoord:</label>
                    <input type={"password"} id={"password-login"} value={password} onChange={passwordHandler}/>
                </div>

                <div className={"button-container-login"}>
                    <button id={"confirm-login"} onClick={loginButton}>Inloggen</button>
                </div>
                {loginError && <p>{loginError}</p>}
            </div>
        </div>
    )
}




export default LoginPage;