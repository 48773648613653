import Modal from "../../../UI/Modal";
import "./BloemenPopup.css"
import logo from "../../../images/Logo Gerard.png"
import CloseButtonModal from "../../../UI/CloseButtonModal";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import BloemenInfoPopup from "./bloemenInfoPopup";
import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BloemenPopupAanpassen from "./BloemenPopupAanpassen";
import Axios from "axios";


const BloemenPopup = props => {
    const [bloemenData, setBloemenData] = useState([])
    const [menubarShow, setMenuBarShow] = useState(false)
    const [deleteBloem, setDeleteBloem] = useState(false)
    const [aanpassenBloemShow, setAanpassenBloemShow] = useState(false)
    const [bloemenAanpasData, setBloemenAanpasData] = useState({})
    const { id } = useParams()

    const handleDataAanpassen = (data) => {
        setBloemenAanpasData(data)
    }
    const showMenuBarHandler = () => {
        setMenuBarShow(true)
    }
    const showMenuBarHandlerFalse = () => {
        setMenuBarShow(false)
    }
    const showDeleteFlower = () => {
        setDeleteBloem(true)
    }
    const hideDeleteFlower = () => {
        setDeleteBloem(false)
    }

    const showAanpassingen = () => {
        setAanpassenBloemShow(true)
    }
    const hideAanpassingen = () => {
        setAanpassenBloemShow(false)
    }
    useEffect(() => {
        fetch(`/bloemId${id.toString()}`)
            .then(res => {
                return res.json()
            })
            .then(data => {
                console.log(data)
                setBloemenData(data)
            })
    }, [])

    const deleteBloemFromDb = e => {
        e.preventDefault()
        console.log("Verwijder Bloem")
        Axios.post(`/deleteBloem${id}`)
        window.open('/bloemen', "_self")
    }

    const getBloem = bloemenData.map(bloem =>
        <BloemenInfoPopup
            key={bloem.naam}
            name={bloem.naam}
            description={bloem.descriptie}
            location={bloem.herkomst}
        />
    )
    const getBloemAanpassen = bloemenData.map(bloem =>
        <BloemenPopupAanpassen
            key={bloem.naam}
            naam={bloem.naam}
            descriptie={bloem.descriptie}
            herkomst={bloem.herkomst}
            onDataUpdate={handleDataAanpassen}
        />
    )

    const aanpassingenBloemOpslaan = e => {
        e.preventDefault()

        try {
            Axios.post(`/veranderBloem/${id}`, {
                naam: bloemenAanpasData.naam,
                descriptie: bloemenAanpasData.descriptie,
                herkomst: bloemenAanpasData.herkomst
            })

            window.open(`/bloemen/${bloemenAanpasData.naam}`, "_self")
        } catch (err) {
            console.log(err)
        }


        hideAanpassingen(false)
    }
    return (
        <Modal>
            <div onClick={props.onClosePopup}>
                <Link to={`/bloemen`}>
                <CloseButtonModal onClickClosePopupBloem={props.onCLosePopup}/>
            </Link>
            </div>
            <FontAwesomeIcon icon={faEllipsisVertical} style={{color: "#000000",}} className={"ellipsis"}
                onClick={showMenuBarHandler}
            />
            <div className={"popup-bloem"}>
                <div className={"popup-bloem__img-container"}>
                <img src={bloemenData.image} alt={"Bloem"}/>
                </div>
                <div className={`${menubarShow ? "menubar-klant-show" : "menubar-klant-hidden"}`}>
                    <div className={"menubar-klant__item"} onClick={showAanpassingen}>
                        <p>aanpassen</p>
                    </div>
                    <div className={"menubar-klant__item"} onClick={showDeleteFlower}>
                        <p>verwijderen</p>
                    </div>
                </div>
                <div onClick={showMenuBarHandlerFalse}
                     className={`${aanpassenBloemShow ? "aanpassingen-klant-hidden" : "aanpassingen-klant"}`}>
                    {getBloem}
                </div>
                <div onClick={showMenuBarHandlerFalse}
                     className={`${aanpassenBloemShow ? "aanpassingen-klant" : "aanpassingen-klant-hidden"}`}>
                    {getBloemAanpassen}
                    <button id={"Aanpassingen-opslaan__klant"} onClick={aanpassingenBloemOpslaan}>Opslaan</button>
                </div>
            </div>

            <div className={`${deleteBloem ? "remove-klant" : "remove-klant-hidden"}`}>
                <h2>Weet je het zeker?</h2>
                <div className={"button-remove-klant-set"}>
                    <button id={"button-remove-klant__confirm"} onClick={deleteBloemFromDb}>Ja</button>
                    <button id={"button-remove-klant__deny"} onClick={hideDeleteFlower}>Nee</button>
                </div>
            </div>
        </Modal>
    )
}

export default BloemenPopup