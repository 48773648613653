import React, {useEffect, useState} from "react";
import "./bloemen.css"
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BloemenItem from "./BloemenItem/BloemenItem";
import Card from "../../UI/Card";
import {Link} from "react-router-dom";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
const Bloemen = (props) => {
    const [flowerId, setFlowerId] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [bloemenData, setBloemenData] = useState([])
    const [filteredBloemenData, setFilteredBloemenData] = useState(bloemenData)
    const flowerHandler = (e) => {
        setFlowerId(e.target.value)
    }

    useEffect(() => {
        fetch("/getBloemen")
            .then(res => {
                return res.json()
            })
            .then(data => {
                console.log(data)
                setBloemenData(data)
                setFilteredBloemenData(data)
            })
    }, [])

    const listBloemen = filteredBloemenData.map(flower =>
        <Link to={`/bloemen/${flower.naam}`} className={"bloemen-link"}>
            <BloemenItem
                key={flower.id}
                id={flower.id}
                name={flower.naam}
                description={flower.descriptie}
                location={flower.herkomst}
                onClickForPopup={props.onShowPopupBloem}
                image={flower.image}
                onClickForId={flowerHandler}
            />
        </Link>
    )

    const searchChangeHandler = e => {
        const searchText = e.target.value.toLowerCase();
        if (searchText === null) {
            setFilteredBloemenData(bloemenData)
        } else {
            const filteredData = bloemenData.filter((flower) =>
                flower.naam.toLowerCase().includes(searchText) ||
                flower.descriptie.toLowerCase().includes(searchText) ||
                flower.herkomst.toLowerCase().includes(searchText)
            );

            setFilteredBloemenData(filteredData)
        }
        setSearchValue(searchText)
    }
    return (
        <div className={"base-container"}>
            <div className={"searchbar"}>
                <div className={"searchbar-wrapper"}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} rotation={90} size="lg" style={{color: "#000000",}}/>
                    <input
                        id="bollen-zoeken"
                        type={"search"}
                        placeholder={"zoek naar bollen of ziektes..."}
                        value={searchValue}
                        onChange={searchChangeHandler}
                    />
                </div>
            </div>
            <Link to={'/bloemen/invoer'}>
                <button id={"add-bloemen"}><FontAwesomeIcon icon={faPlus} size="lg"/>Voeg Toe</button>
            </Link>
            <div className={"card-section"}>
                <Card>
                    {listBloemen}
                </Card>
            </div>

        </div>
    )
}

export default Bloemen