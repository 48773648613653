import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./Onderzoek.css";
import KlantenSelect from "./klantenSelect/klantenSelect";
import OnderzoeksFormulier from "./OnderzoeksFormulier/OnderzoeksFormulier";
import { Link } from "react-router-dom";
import klanten from "../klanten/klanten";

const Onderzoek = () => {
    const [klantenNaam, setKlantenNaam] = useState([]);
    const [geselecteerdeKlantData, setGeselecteerdeKlantData] = useState(null);


    useEffect(() => {
        fetch("/getKlanten")
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                console.log(data);
                setKlantenNaam(data);
            });
    }, []);

    const handleKlantSelect = (e) => {
        const selectedId = e.target.value
        const selectedKlant = klantenNaam.find((klant) => klant._id === selectedId)

        if (selectedKlant) {
            setGeselecteerdeKlantData(selectedKlant)
            console.log(selectedKlant)
            console.log("veranderd")
        } else {
            console.log("Klant niet gevonden")
        }
    };

    return (
        <div className={"base-container"}>
            <label className={"relatie-list"}>
                Relatie:
                <select className={"select-dropdown"} onChange={handleKlantSelect}>
                    <option disabled selected value>
                        -- selecteer klant --
                    </option>
                    {klantenNaam.map((naam) => (
                        <KlantenSelect
                            id={naam._id}
                            key={naam._id}
                            value={naam._id}
                            name={naam.naam}
                            adres={naam.adres}
                            woonplaats={naam.stad}
                            postcode={naam.postcode}
                            kvk={naam.kvk}
                        />
                    ))}
                </select>
            </label>

            <OnderzoeksFormulier
                geselecteerdeKlantData={geselecteerdeKlantData}
            />
        </div>
    );
};

export default Onderzoek;