import KlantenSelect from "../../Onderzoek/klantenSelect/klantenSelect";
import "./AfsprakenPopup.css"
import Modal from "../../../UI/Modal";
import CloseButtonModal from "../../../UI/CloseButtonModal";
import {useEffect, useState} from "react";
import Axios from "axios";

const AfsprakenPopup = props => {
    const [klantenData, setKlantenData] = useState([]);
    const [relatie, setRelatie] = useState("")
    const [datum, setDatum] = useState("")
    const [tijd, setTijd] = useState("")
    const [stad, setStad] = useState("")
    const [adres, setAdres] = useState("")
    const [contactpersoon, setContactpersoon] = useState("")

    const relatieHandler = e => {
        console.log(relatie)
        setRelatie(e.target.value);
        console.log(relatie)
    }
    console.log(relatie)
    const datumHandler = e => {
        setDatum(e.target.value);
    }
    const tijdHandler = e => {
        setTijd(e.target.value)
    }
    const adresHandler = e => {
        setAdres(e.target.value)
    }
    const contactpersoonHandler = e => {
        setContactpersoon(e.target.value)
    }
    const stadHandler = e => {
        setStad(e.target.value)
    }
    const saveHandler = (e) => {
        e.preventDefault()
        try {
            Axios.post('/afspraak-inplannen', {
                relatie: relatie,
                datum: datum,
                tijd: tijd,
                stad: stad,
                adres: adres,
                contactpersoon: contactpersoon
            })
        } catch (err) {
            console.log(err)
        }
        window.open("/home", "_self")
    }

    useEffect(() => {
        fetch("/getKlanten")
            .then(res => {
                return res.json()
            })
            .then(data => {
                console.log(data)
                setKlantenData(data)
            })
    }, [])

    const optionsKlanten = klantenData.map(klant =>
        <option key={klant.id} value={klant.naam}>
            {klant.naam}
        </option>
    )

    return (
        <Modal>
            <div onClick={props.onClosePopup}>
                <CloseButtonModal onClosePopupAfspraak={props.onClosePopup}/>
            </div>
            <div className={"Afspraken-popup"}>
                <div>
                    <div className={"afspraken-popup__formset"}>
                        <label>Selecteer relatie:</label>
                        <select onChange={relatieHandler} value={relatie}>
                            {optionsKlanten}
                        </select>
                    </div>

                    <div className={"afspraken-popup__formset"}>
                        <label>Datum: </label>
                        <input type={"date"} onChange={datumHandler} value={datum}/>
                    </div>
                    <div className={"afspraken-popup__formset"}>
                        <label>Tijd:</label>
                        <input type={"time"} onChange={tijdHandler} value={tijd}/>
                    </div>
                    <div className={"afspraken-popup__formset"}>
                        <label>Stad:</label>
                        <input type={"text"} onChange={stadHandler} value={stad}/>
                    </div>
                    <div className={"afspraken-popup__formset"}>
                        <label>Adres:</label>
                        <input value={adres} onChange={adresHandler} value={adres}/>
                    </div>
                    <div className={"afspraken-popup__formset"}>
                        <label>Contactpersoon:</label>
                        <input type={"text"} onChange={contactpersoonHandler} value={contactpersoon}/>
                    </div>
                    <div className={"afspraken-popup__formset"}></div>
                </div>

                <button className={"opslaan-afspraak"} onClick={saveHandler}>Opslaan</button>
            </div>
        </Modal>
    )
}
export default AfsprakenPopup