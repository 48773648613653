import React, {useEffect, useState} from "react";
import "./OnderzoeksFormulier.css"
import Axios from "axios";

const OnderzoeksFormulier = ({geselecteerdeKlantData}) => {
    const [aantalPerKrat, setAantalPerKrat] = useState("")
    const [aantalKrat1, setAantalKrat1] = useState("");
    const [aantalKrat2, setAantalKrat2] = useState("");
    const [aantalKrat3, setAantalKrat3] = useState("");
    const [aantalKratGem, setAantalKratGem] = useState("");
    const [teKleinKrat1, setTeKleinKrat1] = useState("");
    const [teKleinKrat2, setTeKleinKrat2] = useState("");
    const [teKleinKrat3, setTeKleinKrat3] = useState("");
    const [teKleinGem, setTeKleinGem] = useState("");
    const [teGrootKrat1, setTeGrootKrat1] = useState("");
    const [teGrootKrat2, setTeGrootKrat2] = useState("");
    const [teGrootKrat3, setTeGrootKrat3] = useState("");
    const [teGrootKratGem, setTeGrootKratGem] = useState("");
    const [schadeKrat1, setSchadeKrat1] = useState("")
    const [schadeKrat2, setSchadeKrat2] = useState("")
    const [schadeKrat3, setSchadeKrat3] = useState("")
    const [schadeKratGem, setSchadeKratGem] = useState("")
    const [zachtrotKrat1, setZachtrotKrat1] = useState("")
    const [zachtrotKrat2, setZachtrotKrat2] = useState("")
    const [zachtrotKrat3, setZachtrotKrat3] = useState("")
    const [zachtrotGem, setZachtrotGem] = useState("")
    const [penicilliumKrat1, setPenicilliumKrat1] = useState("")
    const [penicilliumKrat2, setPenicilliumKrat2] = useState("")
    const [penicilliumKrat3, setPenicilliumKrat3] = useState("")
    const [penicilliumGem, setPenicilliumGem] = useState("")
    const [mijtenKrat1, setMijtenKrat1] = useState("")
    const [mijtenKrat2, setMijtenKrat2] = useState("")
    const [mijtenKrat3, setMijtenKrat3] = useState("")
    const [mijtenGem, setMijtenGem] = useState("")
    const [fusariumKrat1, setFusariumKrat1] = useState("")
    const [fusariumKrat2, setFusariumKrat2] = useState("")
    const [fusariumKrat3, setFusariumKrat3] = useState("")
    const [fusariumGem, setFusariumGem] = useState("")
    const [steelKrat1, setSteelKrat1] = useState("")
    const [steelKrat2, setSteelKrat2] = useState("")
    const [steelKrat3, setSteelKrat3] = useState("")
    const [steelKratGem, setSteelKratGem] = useState("")
    const [spruithoogteKrat1, setSpruithoogteKrat1] = useState("")
    const [spruithoogteKrat2, setHoogteKrat2] = useState("")
    const [spruithoogteKrat3, setHoogteKrat3] = useState("")
    const [spruithoogteGem, setHoogteGem] = useState("")
    const [waterschadeKrat1, setWaterschadeKrat1] = useState("")
    const [waterschadeKrat2, setWaterschadeKrat2] = useState("")
    const [waterschadeKrat3, setWaterschadeKrat3] = useState("")
    const [waterschadeGem, setWaterschadeGem] = useState("")
    const [beworteldKrat1, setBeworteldKrat1] = useState("")
    const [beworteldKrat2, setBeworteldKrat2] = useState("")
    const [beworteldKrat3, setBeworteldKrat3] = useState("")
    const [beworteldGem, setBeworteldGem] = useState("")
    const [dubbelNsKrat1, setDubbelNsKrat1] = useState("")
    const [dubbelNsKrat2, setDubbelNsKrat2] = useState("")
    const [dubbelNsKrat3, setDubbelNsKrat3] = useState("")
    const [dubbelNsGem, setDubbelNsGem] = useState("")
    const [gewichtKrat1, setGewichtKrat1] = useState("")
    const [gewichtKrat2, setGewichtKrat2] = useState("")
    const [gewichtKrat3, setGewichtKrat3] = useState("")
    const [gewichtKratGem, setGewichtKratGem] = useState("")
    const [zwarteSpruitKrat1, setZwarteSpruitKrat1] = useState("")
    const [zwarteSpruitKrat2, setZwarteSpruitKrat2] = useState("")
    const [zwarteSpruitKrat3, setZwarteSpruitKrat3] = useState("")
    const [zwarteSpruitGem, setZwarteSpruitGem] = useState("")
    const [drogeStof, setDrogeStof] = useState("")
    const [partij1, setPartij1] = useState("")
    const [partij2, setPartij2] = useState("")
    const [certificaatNr, setCertificaatNr] = useState("")
    const [eigenPartijNr, setEigenPartijNr] = useState("")
    const [datumKeuring, setDatumKeuring] = useState("")
    const [soort, setSoort] = useState("")
    const [maat, setMaat] = useState("")
    const [oogstjaar, setOogstjaar] = useState("")
    const [koopbrief, setKoopbrief] = useState("")
    const [vrachtbrief, setVrachtbrief] = useState("")
    const [ontvangstDatum, setOntvangstDatum] = useState("");
    const [laadDatum, setLaadDatum] = useState("");
    const [keuring, setKeuring] = useState("");
    const [keurmeester, setKeurmeester] = useState("")
    const [aantalStuks, setAantalStuks] = useState("")
    const [aantalKratten, setAantalKratten] = useState("")
    const [herkomst, setHerkomst] = useState("")
    const [prevOntsm, setPrevOntsm] = useState("")
    const [indruk, setIndruk] = useState("")
    const [gespoeld, setGespoeld] = useState("")
    const [behouden, setBehouden] = useState("")
    const [foto, setFoto] = useState("")
    const [opmerking, setOpmerking] = useState("")
    {/* aantal per krat SET */
    }
    const drogestofChange = e => {
        setDrogeStof(e.target.value)
    }
    const keurmeesterChange = e => {
        setKeurmeester(e.target.value)
    }
    const partij1Change = e => {
        setPartij1(e.target.value)
    }
    const partij2Change = e => {
        setPartij2(e.target.value)
    }
    const certifcaatChange = e => {
        setCertificaatNr(e.target.value)
    }
    const eigenCertificaatChange = e => {
        setEigenPartijNr(e.target.value)
    }
    const datumKeuringChange = e => {
        setDatumKeuring(e.target.value)
    }
    const soortChange = e => {
        setSoort(e.target.value)
    }
    const maatChange = e => {
        setMaat(e.target.value)
    }
    const oogstjaarChange = e => {
        setOogstjaar(e.target.value)
    }
    const koopbriefChange = e => {
        setKoopbrief(e.target.value)
    }
    const vrachtbriefChange = e => {
        setVrachtbrief(e.target.value)
    }
    const ontvangstDatumChange = e => {
        setOntvangstDatum(e.target.value)
    }
    const laadDatumChange = e => {
        setLaadDatum(e.target.value)
    }
    const keuringChange = e => {
        setKeuring(e.target.value)
    }
    const aantalStuksChange = e => {
        setAantalStuks(e.target.value)
    }
    const aantalKrattenChange = e => {
        setAantalKratten(e.target.value)
    }
    const aantalPerKratChange = e => {
        setAantalPerKrat(e.target.value)
    }
    const herkomstChange = e => {
        setHerkomst(e.target.value)
    }
    const prevOntsmChange = e => {
        setPrevOntsm(e.target.value)
    }
    const indrukChange = e => {
        setIndruk(e.target.value)
    }
    const gespoeldChange = e => {
        setGespoeld(e.target.value)
    }
    const behoudenChange = e => {
        setBehouden(e.target.value)
    }
    const fotoChange = e => {
        setFoto(e.target.value)
    }
    const opmerkingChange = e => {
        setOpmerking(e.target.value)
    }
    const aantalKrat1Change = (e) => {
        setAantalKrat1(e.target.value)
    }
    const aantalKrat2Change = (e) => {
        setAantalKrat2(e.target.value)
    }
    const aantalKrat3Change = (e) => {
        setAantalKrat3(e.target.value)
    }

    {/* te klein set */
    }
    const teKleinKrat1Change = (e) => {
        setTeKleinKrat1(e.target.value)
    }
    const teKleinKrat2Change = (e) => {
        setTeKleinKrat2(e.target.value)
    }
    const teKleinKrat3Change = (e) => {
        setTeKleinKrat3(e.target.value)
    }
    {/* te groot set */
    }
    const teGrootKrat1Change = (e) => {
        setTeGrootKrat1(e.target.value)
    }
    const teGrootKrat2Change = (e) => {
        setTeGrootKrat2(e.target.value)
    }
    const teGrootKrat3Change = (e) => {
        setTeGrootKrat3(e.target.value)
    }
    {/* schade krat set */
    }
    const schadeKrat1Change = (e) => {
        setSchadeKrat1(e.target.value)
    }
    const schadeKrat2Change = (e) => {
        setSchadeKrat2(e.target.value)
    }
    const schadeKrat3Change = (e) => {
        setSchadeKrat3(e.target.value)
    }
    {/* zachtrot set */
    }
    const zachtrotKrat1Change = (e) => {
        setZachtrotKrat1(e.target.value)
    }
    const zachtrotKrat2Change = (e) => {
        setZachtrotKrat2(e.target.value)
    }
    const zachtrotKrat3Change = (e) => {
        setZachtrotKrat3(e.target.value)
    }

    {/* Penicillium set */
    }
    const penicilliumKrat1Change = (e) => {
        setPenicilliumKrat1(e.target.value)
    }
    const penicilliumKrat2Change = (e) => {
        setPenicilliumKrat2(e.target.value)
    }
    const penicilliumKrat3Change = (e) => {
        setPenicilliumKrat3(e.target.value)
    }

    {/* mijten set */
    }
    const mijtenKrat1Change = (e) => {
        setMijtenKrat1(e.target.value)
    }
    const mijtenKrat2Change = (e) => {
        setMijtenKrat2(e.target.value)
    }
    const mijtenKrat3Change = (e) => {
        setMijtenKrat3(e.target.value)
    }

    {/* Fusarium set */
    }
    const fusariumKrat1Change = (e) => {
        setFusariumKrat1(e.target.value)
    }
    const fusariumKrat2Change = (e) => {
        setFusariumKrat2(e.target.value)
    }
    const fusariumKrat3Change = (e) => {
        setFusariumKrat3(e.target.value)
    }

    {/* Steel set */
    }
    const steelKrat1Change = (e) => {
        setSteelKrat1(e.target.value)
    }
    const steelKrat2Change = (e) => {
        setSteelKrat2(e.target.value)
    }
    const steelKrat3Change = (e) => {
        setSteelKrat3(e.target.value)
    }

    {/* Waterschade set */
    }
    const waterschadeKrat1Change = (e) => {
        setWaterschadeKrat1(e.target.value)
    }
    const waterschadeKrat2Change = (e) => {
        setWaterschadeKrat2(e.target.value)
    }
    const waterschadeKrat3Change = (e) => {
        setWaterschadeKrat3(e.target.value)
    }

    {/* DubbelNs set */
    }
    const dubbelNsKrat1Change = (e) => {
        setDubbelNsKrat1(e.target.value)
    }
    const dubbelNsKrat2Change = (e) => {
        setDubbelNsKrat2(e.target.value)
    }
    const dubbelNsKrat3Change = (e) => {
        setDubbelNsKrat3(e.target.value)
    }

    {/* Gewicht set */
    }
    const gewichtKrat1Change = (e) => {
        setGewichtKrat1(e.target.value)
    }
    const gewichtKrat2Change = (e) => {
        setGewichtKrat2(e.target.value)
    }
    const gewichtKrat3Change = (e) => {
        setGewichtKrat3(e.target.value)
    }

    {/* ZwarteSpruit set */
    }
    const zwarteSpruitKrat1Change = (e) => {
        setZwarteSpruitKrat1(e.target.value)
    }
    const zwarteSpruitKrat2Change = (e) => {
        setZwarteSpruitKrat2(e.target.value)
    }
    const zwarteSpruitKrat3Change = (e) => {
        setZwarteSpruitKrat3(e.target.value)
    }
    const fileSelecterHandler = e => {
        setPhoto(e.target.file)
    }
    const [photo, setPhoto] = useState(null)

    const calculateAantalGem = () => {
        let calculate;
        let aantal;

        if (aantalKrat1 === "" && aantalKrat2 === "" && aantalKrat3 === "") {
            aantal = "0"
        } else if (aantalKrat1 !== "" && aantalKrat2 === "" && aantalKrat3 === "") {
            aantal = aantalKrat1
        } else if (aantalKrat1 !== "" && aantalKrat2 !== "" && aantalKrat3 === "") {
            calculate = Math.round((parseInt(aantalKrat1) + parseInt(aantalKrat2)))
            aantal = calculate / 2
        } else if (aantalKrat1 !== "" && aantalKrat2 !== "" && aantalKrat3 !== "") {
            calculate = Math.round((parseInt(aantalKrat1) + parseInt(aantalKrat2) + parseInt(aantalKrat3)))
            aantal = calculate / 3
        } else if (aantalKrat1 === "" && aantalKrat2 !== "" && aantalKrat3 === "") {
            aantal = aantalKrat2
        } else if (aantalKrat1 === "" && aantalKrat2 === "" && aantalKrat3 !== "") {
            aantal = aantalKrat3
        } else if (aantalKrat1 !== "" && aantalKrat2 === "" && aantalKrat3 !== "") {
            calculate = Math.round((parseInt(aantalKrat1) + parseInt(aantalKrat3)))
            aantal = calculate / 2
        } else if (aantalKrat1 === "" && aantalKrat2 !== "" && aantalKrat3 !== "") {
            calculate = Math.round(parseInt(aantalKrat2) + parseInt(aantalKrat3))
            aantal = calculate / 2
        }
        return aantal
    }
    const calculateTeKleinGem = () => {
        let calculate;
        let aantal;

        if (teKleinKrat1 === "" && teKleinKrat2 === "" && teKleinKrat3 === "") {
            aantal = "0"
        } else if (teKleinKrat1 !== "" && teKleinKrat2 === "" && teKleinKrat3 === "") {
            aantal = teKleinKrat1
        } else if (teKleinKrat1 !== "" && teKleinKrat2 !== "" && teKleinKrat3 === "") {
            calculate = Math.round((parseInt(teKleinKrat1) + parseInt(teKleinKrat2)))
            aantal = calculate / 2
        } else if (teKleinKrat1 !== "" && teKleinKrat2 !== "" && teKleinKrat3 !== "") {
            calculate = Math.round((parseInt(teKleinKrat1) + parseInt(teKleinKrat2) + parseInt(teKleinKrat3)))
            aantal = calculate / 3
        } else if (teKleinKrat1 === "" && teKleinKrat2 !== "" && teKleinKrat3 === "") {
            aantal = teKleinKrat2
        } else if (teKleinKrat1 === "" && teKleinKrat2 === "" && teKleinKrat3 !== "") {
            aantal = teKleinKrat3
        } else if (teKleinKrat1 !== "" && teKleinKrat2 === "" && teKleinKrat3 !== "") {
            calculate = Math.round((parseInt(teKleinKrat1) + parseInt(teKleinKrat3)))
            aantal = calculate / 2
        } else if (teKleinKrat1 === "" && teKleinKrat2 !== "" && teKleinKrat3 !== "") {
            calculate = Math.round(parseInt(teKleinKrat2) + parseInt(teKleinKrat3))
            aantal = calculate / 2
        }
        return aantal
    }
    const calculateTeGrootGem = () => {
        let calculate;
        let aantal;

        if (teGrootKrat1 === "" && teGrootKrat2 === "" && teGrootKrat3 === "") {
            aantal = "0"
        } else if (teGrootKrat1 !== "" && teGrootKrat2 === "" && teGrootKrat3 === "") {
            aantal = teGrootKrat1
        } else if (teGrootKrat1 !== "" && teGrootKrat2 !== "" && teGrootKrat3 === "") {
            calculate = Math.round((parseInt(teGrootKrat1) + parseInt(teGrootKrat2)))
            aantal = calculate / 2
        } else if (teGrootKrat1 !== "" && teGrootKrat2 !== "" && teGrootKrat3 !== "") {
            calculate = Math.round((parseInt(teKleinKrat1) + parseInt(teKleinKrat2) + parseInt(teKleinKrat3)))
            aantal = calculate / 3
        } else if (teGrootKrat1 === "" && teGrootKrat2 !== "" && teGrootKrat3 === "") {
            aantal = teGrootKrat2
        } else if (teGrootKrat1 === "" && teGrootKrat2 === "" && teGrootKrat3 !== "") {
            aantal = teKleinKrat3
        } else if (teGrootKrat1 !== "" && teGrootKrat2 === "" && teGrootKrat3 !== "") {
            calculate = Math.round((parseInt(teGrootKrat1) + parseInt(teGrootKrat3)))
            aantal = calculate / 2
        } else if (teGrootKrat1 === "" && teGrootKrat2 !== "" && teGrootKrat3 !== "") {
            calculate = Math.round(parseInt(teGrootKrat2) + parseInt(teGrootKrat3))
            aantal = calculate / 2
        }
        return aantal;
    }
    const calculateSchadeGem = () => {
        let calculate;
        let aantal;

        if (schadeKrat1 === "" && schadeKrat2 === "" && schadeKrat3 === "") {
            aantal = "0"
        } else if (schadeKrat1 !== "" && schadeKrat2 === "" && schadeKrat3 === "") {
            aantal = schadeKrat1
        } else if (schadeKrat1 !== "" && schadeKrat2 !== "" && schadeKrat3 === "") {
            calculate = Math.round((parseInt(schadeKrat1) + parseInt(schadeKrat2)))
            aantal = calculate / 2
        } else if (schadeKrat1 !== "" && schadeKrat2 !== "" && schadeKrat3 !== "") {
            calculate = Math.round((parseInt(schadeKrat1) + parseInt(schadeKrat2) + parseInt(schadeKrat3)))
            aantal = calculate / 3
        } else if (schadeKrat1 === "" && schadeKrat2 !== "" && schadeKrat3 === "") {
            aantal = schadeKrat2
        } else if (schadeKrat1 === "" && schadeKrat2 === "" && schadeKrat3 !== "") {
            aantal = teKleinKrat3
        } else if (schadeKrat1 !== "" && schadeKrat2 === "" && schadeKrat3 !== "") {
            calculate = Math.round((parseInt(schadeKrat1) + parseInt(schadeKrat3)))
            aantal = calculate / 2
        } else if (schadeKrat1 === "" && schadeKrat2 !== "" && schadeKrat3 !== "") {
            calculate = Math.round(parseInt(schadeKrat2) + parseInt(schadeKrat3))
            aantal = calculate / 2
        }
        return aantal;
    }
    const calculateZachtrotGem = () => {
        let calculate;
        let aantal;

        if (zachtrotKrat1 === "" && zachtrotKrat2 === "" && zachtrotKrat3 === "") {
            aantal = "0"
        } else if (zachtrotKrat1 !== "" && zachtrotKrat2 === "" && zachtrotKrat3 === "") {
            aantal = zachtrotKrat1
        } else if (zachtrotKrat1 !== "" && zachtrotKrat2 !== "" && zachtrotKrat3 === "") {
            calculate = Math.round((parseInt(zachtrotKrat1) + parseInt(zachtrotKrat2)))
            aantal = calculate / 2
        } else if (zachtrotKrat1 !== "" && zachtrotKrat2 !== "" && zachtrotKrat3 !== "") {
            calculate = Math.round((parseInt(zachtrotKrat1) + parseInt(zachtrotKrat2) + parseInt(zachtrotKrat3)))
            aantal = calculate / 3
        } else if (zachtrotKrat1 === "" && zachtrotKrat2 !== "" && zachtrotKrat3 === "") {
            aantal = zachtrotKrat2
        } else if (zachtrotKrat1 === "" && zachtrotKrat2 === "" && zachtrotKrat3 !== "") {
            aantal = zachtrotKrat3
        } else if (zachtrotKrat1 !== "" && zachtrotKrat2 === "" && zachtrotKrat3 !== "") {
            calculate = Math.round((parseInt(zachtrotKrat1) + parseInt(zachtrotKrat3)))
            aantal = calculate / 2
        } else if (zachtrotKrat1 === "" && zachtrotKrat2 !== "" && zachtrotKrat3 !== "") {
            calculate = Math.round(parseInt(zachtrotKrat2) + parseInt(zachtrotKrat3))
            aantal = calculate / 2
        }
        return aantal;
    }

    const calculatePenicilliumGem = () => {
        let calculate;
        let aantal;

        if (penicilliumKrat1 === "" && penicilliumKrat2 === "" && penicilliumKrat3 === "") {
            aantal = "0"
        } else if (penicilliumKrat1 !== "" && penicilliumKrat2 === "" && penicilliumKrat3 === "") {
            aantal = penicilliumKrat1
        } else if (penicilliumKrat1 !== "" && penicilliumKrat2 !== "" && penicilliumKrat3 === "") {
            calculate = Math.round((parseInt(penicilliumKrat1) + parseInt(penicilliumKrat2)))
            aantal = calculate / 2
        } else if (penicilliumKrat1 !== "" && penicilliumKrat2 !== "" && penicilliumKrat3 !== "") {
            calculate = Math.round((parseInt(penicilliumKrat1) + parseInt(penicilliumKrat2) + parseInt(penicilliumKrat3)))
            aantal = calculate / 3
        } else if (penicilliumKrat1 === "" && penicilliumKrat2 !== "" && penicilliumKrat3 === "") {
            aantal = penicilliumKrat2
        } else if (penicilliumKrat1 === "" && penicilliumKrat2 === "" && penicilliumKrat3 !== "") {
            aantal = penicilliumKrat3
        } else if (penicilliumKrat1 !== "" && penicilliumKrat2 === "" && penicilliumKrat3 !== "") {
            calculate = Math.round((parseInt(penicilliumKrat1) + parseInt(penicilliumKrat3)))
            aantal = calculate / 2
        } else if (penicilliumKrat1 === "" && penicilliumKrat2 !== "" && penicilliumKrat3 !== "") {
            calculate = Math.round(parseInt(penicilliumKrat2) + parseInt(penicilliumKrat3))
            aantal = calculate / 2
        }
        return aantal;
    }

    const calculateMijtenGem = () => {
        let calculate;
        let aantal;
        if (mijtenKrat1 === "" && mijtenKrat2 === "" && mijtenKrat3 === "") {
            aantal = "0";
        } else if (mijtenKrat1 !== "" && mijtenKrat2 === "" && mijtenKrat3 === "") {
            aantal = mijtenKrat1;
        } else if (mijtenKrat1 !== "" && mijtenKrat2 !== "" && mijtenKrat3 === "") {
            calculate = Math.round(parseInt(mijtenKrat1) + parseInt(mijtenKrat2));
            aantal = calculate / 2;
        } else if (mijtenKrat1 !== "" && mijtenKrat2 !== "" && mijtenKrat3 !== "") {
            calculate = Math.round(parseInt(mijtenKrat1) + parseInt(mijtenKrat2) + parseInt(mijtenKrat3));
            aantal = calculate / 3;
        } else if (mijtenKrat1 === "" && mijtenKrat2 !== "" && mijtenKrat3 === "") {
            aantal = mijtenKrat2;
        } else if (mijtenKrat1 === "" && mijtenKrat2 === "" && mijtenKrat3 !== "") {
            aantal = mijtenKrat3;
        } else if (mijtenKrat1 !== "" && mijtenKrat2 === "" && mijtenKrat3 !== "") {
            calculate = Math.round(parseInt(mijtenKrat1) + parseInt(mijtenKrat3));
            aantal = calculate / 2;
        } else if (mijtenKrat1 === "" && mijtenKrat2 !== "" && mijtenKrat3 !== "") {
            calculate = Math.round(parseInt(mijtenKrat2) + parseInt(mijtenKrat3));
            aantal = calculate / 2;
        }
        return aantal;
    }

    const calculateFusariumGem = () => {
        let calculate;
        let aantal;
        if (fusariumKrat1 === "" && fusariumKrat2 === "" && fusariumKrat3 === "") {
            aantal = "0";
        } else if (fusariumKrat1 !== "" && fusariumKrat2 === "" && fusariumKrat3 === "") {
            aantal = fusariumKrat1;
        } else if (fusariumKrat1 !== "" && fusariumKrat2 !== "" && fusariumKrat3 === "") {
            calculate = Math.round(parseInt(fusariumKrat1) + parseInt(fusariumKrat2));
            aantal = calculate / 2;
        } else if (fusariumKrat1 !== "" && fusariumKrat2 !== "" && fusariumKrat3 !== "") {
            calculate = Math.round(parseInt(fusariumKrat1) + parseInt(fusariumKrat2) + parseInt(fusariumKrat3));
            aantal = calculate / 3;
        } else if (fusariumKrat1 === "" && fusariumKrat2 !== "" && fusariumKrat3 === "") {
            aantal = fusariumKrat2;
        } else if (fusariumKrat1 === "" && fusariumKrat2 === "" && fusariumKrat3 !== "") {
            aantal = fusariumKrat3;
        } else if (fusariumKrat1 !== "" && fusariumKrat2 === "" && fusariumKrat3 !== "") {
            calculate = Math.round(parseInt(fusariumKrat1) + parseInt(fusariumKrat3));
            aantal = calculate / 2;
        } else if (fusariumKrat1 === "" && fusariumKrat2 !== "" && fusariumKrat3 !== "") {
            calculate = Math.round(parseInt(fusariumKrat2) + parseInt(fusariumKrat3));
            aantal = calculate / 2;
        }
        return aantal;
    }

    const calculateSteelGem = () => {
        let calculate;
        let aantal;
        if (steelKrat1 === "" && steelKrat2 === "" && steelKrat3 === "") {
            aantal = "0";
        } else if (steelKrat1 !== "" && steelKrat2 === "" && steelKrat3 === "") {
            aantal = steelKrat1;
        } else if (steelKrat1 !== "" && steelKrat2 !== "" && steelKrat3 === "") {
            calculate = Math.round(parseInt(steelKrat1) + parseInt(steelKrat2));
            aantal = calculate / 2;
        } else if (steelKrat1 !== "" && steelKrat2 !== "" && steelKrat3 !== "") {
            calculate = Math.round(parseInt(steelKrat1) + parseInt(steelKrat2) + parseInt(steelKrat3));
            aantal = calculate / 3;
        } else if (steelKrat1 === "" && steelKrat2 !== "" && steelKrat3 === "") {
            aantal = steelKrat2;
        } else if (steelKrat1 === "" && steelKrat2 === "" && steelKrat3 !== "") {
            aantal = steelKrat3;
        } else if (steelKrat1 !== "" && steelKrat2 === "" && steelKrat3 !== "") {
            calculate = Math.round(parseInt(steelKrat1) + parseInt(steelKrat3));
            aantal = calculate / 2;
        } else if (steelKrat1 === "" && steelKrat2 !== "" && steelKrat3 !== "") {
            calculate = Math.round(parseInt(steelKrat2) + parseInt(steelKrat3));
            aantal = calculate / 2;
        }
        return aantal;
    }
    const calculateWaterschadeGem = () => {
        let calculate;
        let aantal;
        if (waterschadeKrat1 === "" && waterschadeKrat2 === "" && waterschadeKrat3 === "") {
            aantal = "0";
        } else if (waterschadeKrat1 !== "" && waterschadeKrat2 === "" && waterschadeKrat3 === "") {
            aantal = waterschadeKrat1;
        } else if (waterschadeKrat1 !== "" && waterschadeKrat2 !== "" && waterschadeKrat3 === "") {
            calculate = Math.round(parseInt(waterschadeKrat1) + parseInt(waterschadeKrat2));
            aantal = calculate / 2;
        } else if (waterschadeKrat1 !== "" && waterschadeKrat2 !== "" && waterschadeKrat3 !== "") {
            calculate = Math.round(parseInt(waterschadeKrat1) + parseInt(waterschadeKrat2) + parseInt(waterschadeKrat3));
            aantal = calculate / 3;
        } else if (waterschadeKrat1 === "" && waterschadeKrat2 !== "" && waterschadeKrat3 === "") {
            aantal = waterschadeKrat2;
        } else if (waterschadeKrat1 === "" && waterschadeKrat2 === "" && waterschadeKrat3 !== "") {
            aantal = waterschadeKrat3;
        } else if (waterschadeKrat1 !== "" && waterschadeKrat2 === "" && waterschadeKrat3 !== "") {
            calculate = Math.round(parseInt(waterschadeKrat1) + parseInt(waterschadeKrat3));
            aantal = calculate / 2;
        } else if (waterschadeKrat1 === "" && waterschadeKrat2 !== "" && waterschadeKrat3 !== "") {
            calculate = Math.round(parseInt(waterschadeKrat2) + parseInt(waterschadeKrat3));
            aantal = calculate / 2;
        }
        return aantal;
    }
    const calculateDubbelNsGem = () => {
        let calculate;
        let aantal;
        if (dubbelNsKrat1 === "" && dubbelNsKrat2 === "" && dubbelNsKrat3 === "") {
            aantal = "0";
        } else if (dubbelNsKrat1 !== "" && dubbelNsKrat2 === "" && dubbelNsKrat3 === "") {
            aantal = dubbelNsKrat1;
        } else if (dubbelNsKrat1 !== "" && dubbelNsKrat2 !== "" && dubbelNsKrat3 === "") {
            calculate = Math.round(parseInt(dubbelNsKrat1) + parseInt(dubbelNsKrat2));
            aantal = calculate / 2;
        } else if (dubbelNsKrat1 !== "" && dubbelNsKrat2 !== "" && dubbelNsKrat3 !== "") {
            calculate = Math.round(parseInt(dubbelNsKrat1) + parseInt(dubbelNsKrat2) + parseInt(dubbelNsKrat3));
            aantal = calculate / 3;
        } else if (dubbelNsKrat1 === "" && dubbelNsKrat2 !== "" && dubbelNsKrat3 === "") {
            aantal = dubbelNsKrat2;
        } else if (dubbelNsKrat1 === "" && dubbelNsKrat2 === "" && dubbelNsKrat3 !== "") {
            aantal = dubbelNsKrat3;
        } else if (dubbelNsKrat1 !== "" && dubbelNsKrat2 === "" && dubbelNsKrat3 !== "") {
            calculate = Math.round(parseInt(dubbelNsKrat1) + parseInt(dubbelNsKrat3));
            aantal = calculate / 2;
        } else if (dubbelNsKrat1 === "" && dubbelNsKrat2 !== "" && dubbelNsKrat3 !== "") {
            calculate = Math.round(parseInt(dubbelNsKrat2) + parseInt(dubbelNsKrat3));
            aantal = calculate / 2;
        }
        return aantal;
    }
    const calculateGewichtGem = () => {
        let calculate;
        let aantal;

        if (gewichtKrat1 === "" && gewichtKrat2 === "" && gewichtKrat3 === "") {
            aantal = "0";
        } else if (gewichtKrat1 !== "" && gewichtKrat2 === "" && gewichtKrat3 === "") {
            aantal = gewichtKrat1;
        } else if (gewichtKrat1 !== "" && gewichtKrat2 !== "" && gewichtKrat3 === "") {
            calculate = Math.round(parseInt(gewichtKrat1) + parseInt(gewichtKrat2));
            aantal = calculate / 2;
        } else if (gewichtKrat1 !== "" && gewichtKrat2 !== "" && gewichtKrat3 !== "") {
            calculate = Math.round(parseInt(gewichtKrat1) + parseInt(gewichtKrat2) + parseInt(gewichtKrat3));
            aantal = calculate / 3;
        } else if (gewichtKrat1 === "" && gewichtKrat2 !== "" && gewichtKrat3 === "") {
            aantal = gewichtKrat2;
        } else if (gewichtKrat1 === "" && gewichtKrat2 === "" && gewichtKrat3 !== "") {
            aantal = gewichtKrat3;
        } else if (gewichtKrat1 !== "" && gewichtKrat2 === "" && gewichtKrat3 !== "") {
            calculate = Math.round(parseInt(gewichtKrat1) + parseInt(gewichtKrat3));
            aantal = calculate / 2;
        } else if (gewichtKrat1 === "" && gewichtKrat2 !== "" && gewichtKrat3 !== "") {
            calculate = Math.round(parseInt(gewichtKrat2) + parseInt(gewichtKrat3));
            aantal = calculate / 2;
        }
        return aantal;
    }
    const calculateZwarteSpruitGem = () => {
        let calculate;
        let aantal;

        if (zwarteSpruitKrat1 === "" && zwarteSpruitKrat2 === "" && zwarteSpruitKrat3 === "") {
            aantal = "0";
        } else if (zwarteSpruitKrat1 !== "" && zwarteSpruitKrat2 === "" && zwarteSpruitKrat3 === "") {
            aantal = zwarteSpruitKrat1;
        } else if (zwarteSpruitKrat1 !== "" && zwarteSpruitKrat2 !== "" && zwarteSpruitKrat3 === "") {
            calculate = Math.round(parseInt(zwarteSpruitKrat1) + parseInt(zwarteSpruitKrat2));
            aantal = calculate / 2;
        } else if (zwarteSpruitKrat1 !== "" && zwarteSpruitKrat2 !== "" && zwarteSpruitKrat3 !== "") {
            calculate = Math.round(parseInt(zwarteSpruitKrat1) + parseInt(zwarteSpruitKrat2) + parseInt(zwarteSpruitKrat3));
            aantal = calculate / 3;
        } else if (zwarteSpruitKrat1 === "" && zwarteSpruitKrat2 !== "" && zwarteSpruitKrat3 === "") {
            aantal = zwarteSpruitKrat2;
        } else if (zwarteSpruitKrat1 === "" && zwarteSpruitKrat2 === "" && zwarteSpruitKrat3 !== "") {
            aantal = zwarteSpruitKrat3;
        } else if (zwarteSpruitKrat1 !== "" && zwarteSpruitKrat2 === "" && zwarteSpruitKrat3 !== "") {
            calculate = Math.round(parseInt(zwarteSpruitKrat1) + parseInt(zwarteSpruitKrat3));
            aantal = calculate / 2;
        } else if (zwarteSpruitKrat1 === "" && zwarteSpruitKrat2 !== "" && zwarteSpruitKrat3 !== "") {
            calculate = Math.round(parseInt(zwarteSpruitKrat2) + parseInt(zwarteSpruitKrat3));
            aantal = calculate / 2;
        }
        return aantal;
    }

    const [name, setName] = useState("")
    const [adres, setAdres] = useState("")
    const [postcodeNew, setPostcodeNew] = useState("")
    const [woonplaatsNew, setWoonplaatsnew] = useState("")

    useEffect(() => {
        if (geselecteerdeKlantData) {
            setName(geselecteerdeKlantData.naam)
            setAdres(geselecteerdeKlantData.adres)
            setPostcodeNew(geselecteerdeKlantData.postcode)
            setWoonplaatsnew(geselecteerdeKlantData.woonplaats)
        } else {
            setName("")
            setAdres("")
            setPostcodeNew("")
            setWoonplaatsnew("")
        }
    }, [geselecteerdeKlantData])

    function nameSetter() {
        if (!geselecteerdeKlantData) {
            return "";
        }
        return geselecteerdeKlantData.naam

    }

    function adresSetter() {
        if (!geselecteerdeKlantData) {
            return ""
        }
        return geselecteerdeKlantData.adres
    }

    function postcodeSetter() {
        if (!geselecteerdeKlantData) {
            return ""
        }
        return geselecteerdeKlantData.postcode
    }

    function woonplaatsSetter() {
        if (!geselecteerdeKlantData) {
            return ""
        }
        return geselecteerdeKlantData.woonplaats
    }

    const onderzoekNaarDb = e => {
        e.preventDefault()
        console.log("opslaanannaanan")

        Axios.post("/nieuwOnderzoek", {
            relatie: name,
            adres: adres,
            postcode: postcodeNew,
            woonplaats: woonplaatsNew,
            partij1: partij1,
            partij2: partij2,
            certificaatNummer: certificaatNr,
            eigenPartijNr: eigenPartijNr,
            aantalKrat1: aantalKrat1,
            aantalKrat2: aantalKrat2,
            aantalKrat3: aantalKrat3,
            aantalKratGem: aantalKratGem,
            teKleinKrat1: teKleinKrat1,
            teKleinKrat2: teKleinKrat2,
            teKleinKrat3: teKleinKrat3,
            teKleinGem: teKleinGem,
            teGrootKrat1: teGrootKrat1,
            teGrootKrat2: teKleinKrat2,
            teGrootKrat3: teGrootKrat3,
            teGrootGem: teGrootKratGem,
            schadeKrat1: schadeKrat1,
            schadeKrat2: schadeKrat2,
            schadeKrat3: schadeKrat3,
            schadeGem: schadeKratGem,
            zachtrotKrat1: zachtrotKrat1,
            zachtrotKrat2: zachtrotKrat2,
            zachtrotKrat3: zachtrotKrat3,
            zachtrotGem: zachtrotGem,
            penicilliumKrat1: penicilliumKrat1,
            penicilliumKrat2: penicilliumKrat2,
            penicilliumKrat3: penicilliumKrat3,
            penicilliumGem: penicilliumGem,
            mijtenKrat1: mijtenKrat1,
            mijtenKrat2: mijtenKrat2,
            mijtenKrat3: mijtenKrat3,
            mijtenGem: mijtenGem,
            fusariumKrat1: fusariumKrat1,
            fusariumKrat2: fusariumKrat2,
            fusariumKrat3: fusariumKrat3,
            fusariumGem: fusariumGem,
            steelKrat1: steelKrat1,
            steelKrat2: steelKrat2,
            steelKrat3: steelKrat3,
            steelGem: steelKratGem,
            spruithoogteKrat1: spruithoogteKrat1,
            spruithoogteKrat2: spruithoogteKrat2,
            spruithoogteKrat3: spruithoogteKrat3,
            waterschadeKrat1: waterschadeKrat1,
            waterschadeKrat2: waterschadeKrat2,
            waterschadeKrat3: waterschadeKrat3,
            waterschadeGem: waterschadeGem,
            beworteldKrat1: beworteldKrat1,
            beworteldKrat2: beworteldKrat2,
            beworteldKrat3: beworteldKrat3,
            dubbelNSKrat1: dubbelNsKrat1,
            dubbelNsKrat2: dubbelNsKrat2,
            dubbelNsKrat3: dubbelNsKrat3,
            dubbelNsGem: dubbelNsGem,
            gewichtKrat1: gewichtKrat1,
            gewichtKrat2: gewichtKrat2,
            gewichtKrat3: gewichtKrat3,
            gewichtGem: gewichtKratGem,
            zwarteSpruitKrat1: zwarteSpruitKrat1,
            zwarteSpruitKrat2: zwarteSpruitKrat2,
            zwarteSpruitKrat3: zwarteSpruitKrat3,
            zwarteSpruitGem: zwarteSpruitGem,
            drogeStof: drogeStof,
            datumKeuring: datumKeuring,
            soort: soort,
            maat: maat,
            oogstjaar: oogstjaar,
            koopbrief: koopbrief,
            vrachtbrief: vrachtbrief,
            ontvangstDatum: ontvangstDatum,
            laadDatum: laadDatum,
            keuring: keuring,
            keurmeester: keurmeester,
            aantalStuks: aantalStuks,
            aantalKratten: aantalKratten,
            aantalPerKrat: aantalPerKrat,
            herkomst: herkomst,
            prevOntsm: prevOntsm,
            indruk: indruk,
            gespoeld: gespoeld,
            behouden: behouden,
            foto: foto,
            opmerking: opmerking
        })
        window.open("/onderzoek/history", "_self")
    }
    return (
        <form className={"form-onderzoek"}>
            <div className={"form-container"}>
                <div className={"form-onderzoek__section-left"}>
                    <div className={"form-onderzoek__naw-klant"}>
                        <div className={"form-set__naw-klant"}>
                            <label>Naam opdrachtgever: </label>
                            <input
                                type={"text"}
                                value={name}
                                name={"contactpersoon"}
                                onChange={nameSetter()}
                            />
                        </div>
                        <div className={"form-set__naw-klant"}>
                            <label>Adres: </label>
                            <input type={"text"}
                                   value={adres}
                                   onChange={adresSetter()}
                                   name={"adres"}
                            />
                        </div>
                        <div className={"form-set__naw-klant"}>
                            <label>postcode: </label>
                            <input type={"text"} value={postcodeNew} name={"postcode"} onChange={postcodeSetter()}/>
                        </div>
                        <div className={"form-set__naw-klant"}>
                            <label>Woonplaats: </label>
                            <input type={"text"} value={woonplaatsNew} name={"woonplaats"} onChange={woonplaatsSetter()}/>
                        </div>
                    </div>
                    <div className={"form-onderzoek__data"}>
                        <div className={"form-set__data unique-set"}>
                            <label>Partij: </label>
                            <input id={"partij__1"} name={"partij1"} value={partij1} onChange={partij1Change}/>
                            <input id={"partij__2"} name={"partij2"} value={partij2} onChange={partij2Change}/>
                        </div>
                        <div className={"form-set__data"}>
                            <label>CertificaatNummer:</label><input value={certificaatNr} onChange={certifcaatChange}/>
                            <label>Eigen PartijNr:</label><input value={eigenPartijNr} onChange={eigenCertificaatChange}/>
                        </div>
                    </div>
                    <div className="form-grid__data">
                        {/* namen van tabellen */}
                        <div className="krat-1"><strong>Krat 1</strong></div>
                        <div className="krat-2"><strong>Krat 2</strong></div>
                        <div className="krat-3"><strong>Krat 3</strong></div>
                        <div className="aantal"><strong>Aantal</strong></div>
                        <div className="te-klein"><strong>Te Klein</strong></div>
                        <div className="te-groot"><strong>Te Groot</strong></div>
                        <div className="schade"><strong>Schade</strong></div>
                        <div className="zachtrot"><strong>Zachtrot</strong></div>
                        <div className="penicillium"><strong>Penicillium</strong></div>
                        <div className="mijten"><strong>Mijten</strong></div>
                        <div className="fusarium"><strong>Fusarium</strong></div>
                        <div className="steel"><strong>Steel</strong></div>
                        <div className="spruithoogte"><strong>Spruithoogte</strong></div>
                        <div className="water-schade"><strong>Waterschade</strong></div>
                        <div className="bewort"><strong>Beworteld</strong></div>
                        <div className="dubbel-ns"><strong>DubbelNS</strong></div>
                        <div className="gewicht"><strong>Gewicht</strong></div>
                        <div className="zwarte-spruit"><strong>Zwarte Spruit</strong></div>
                        <div className="droge-stof"><strong>Droge Stof</strong></div>
                        <div className="gemiddeld"><strong>Gemiddeld</strong></div>

                        {/* vanaf hier input
                        - aantal
                        */}
                        <div className="aantal__krat-1 form-grid__input"><input
                            type={"number"}
                            value={aantalKrat1}
                            placeholder={"Aantal"}
                            onChange={aantalKrat1Change}

                        /></div>
                        <div className="aantal__krat-2 form-grid__input"><input
                            type={"number"}
                            value={aantalKrat2}
                            placeholder={"aantal"}
                            onChange={aantalKrat2Change}
                        /></div>
                        <div className="aantal__krat-3 form-grid__input"><input
                            type={"number"}
                            value={aantalKrat3}
                            placeholder={"aantal"}
                            onChange={aantalKrat3Change}
                        /></div>
                        <div className="aantal__gem form-grid__input"><input
                            readOnly={true}
                            type={"number"}
                            value={calculateAantalGem()}
                        /></div>

                        {/* te klein */}
                        <div className="te-klein__krat-1 form-grid__input"><input
                            type={"number"}
                            value={teKleinKrat1}
                            placeholder={"Te Klein"}
                            onChange={teKleinKrat1Change}
                        /></div>
                        <div className="te-klein__krat-2 form-grid__input"><input
                            type={"number"}
                            value={teKleinKrat2}
                            placeholder={"Te Klein"}
                            onChange={teKleinKrat2Change}
                        /></div>
                        <div className="te-klein__krat-3 form-grid__input"><input
                            type={"number"}
                            value={teKleinKrat3}
                            placeholder={"Te Klein"}
                            onChange={teKleinKrat3Change}
                        /></div>
                        <div className="te-klein__gem form-grid__input"><input
                            readOnly={true}
                            type={"number"}
                            value={calculateTeKleinGem()}
                        /></div>

                        {/* te groot */}
                        <div className="te-groot__krat-1 form-grid__input"><input
                            type={"number"}
                            value={teGrootKrat1}
                            placeholder={"Te Groot"}
                            onChange={teGrootKrat1Change}
                        /></div>
                        <div className="te-groot__krat-2 form-grid__input"><input
                            type={"number"}
                            value={teGrootKrat2}
                            placeholder={"Te Groot"}
                            onChange={teGrootKrat2Change}
                        /></div>
                        <div className="te-groot__krat-3 form-grid__input"><input
                            type={"number"}
                            value={teGrootKrat3}
                            placeholder={"Te Groot"}
                            onChange={teGrootKrat3Change}
                        /></div>
                        <div className="te-groot__gem form-grid__input"><input
                            type={"number"}
                            value={calculateTeGrootGem()}
                            readOnly={true}
                        /></div>

                        {/* schade */}
                        <div className="schade__krat-1 form-grid__input"><input
                            type={"number"}
                            value={schadeKrat1}
                            placeholder={"Schade"}
                            onChange={schadeKrat1Change}
                        /></div>
                        <div className="schade__krat-2 form-grid__input"><input
                            type={"number"}
                            value={schadeKrat2}
                            placeholder={"Schade"}
                            onChange={schadeKrat2Change}
                        /></div>
                        <div className="schade__krat-3 form-grid__input"><input
                            type={"number"}
                            value={schadeKrat3}
                            placeholder={"Schade"}
                            onChange={schadeKrat3Change}
                        /></div>
                        <div className="schade__gem form-grid__input"><input
                            type={"number"}
                            readOnly={true}
                            value={ calculateSchadeGem()
                            }
                        /></div>

                        {/* zachtrot */}
                        <div className="zachtrot__krat-1 form-grid__input"><input
                            type={"number"}
                            value={zachtrotKrat1}
                            placeholder={"Zachtrot"}
                            onChange={zachtrotKrat1Change}
                        /></div>
                        <div className="zachtrot__krat-2 form-grid__input"><input
                            type={"number"}
                            value={zachtrotKrat2}
                            placeholder={"Zachtrot"}
                            onChange={zachtrotKrat2Change}
                        /></div>
                        <div className="zachtrot__krat-3 form-grid__input"><input
                            type={"number"}
                            value={zachtrotKrat3}
                            placeholder={"Zachtrot"}
                            onChange={zachtrotKrat3Change}
                        /></div>
                        <div className="zachtrot__gem form-grid__input"><input
                            type={"number"}
                            value={calculateZachtrotGem()}
                            readOnly={true}
                        /></div>

                        {/* Penicillium */}
                        <div className="penicillium__krat-1 form-grid__input"><input
                            type={"number"}
                            placeholder={"Penicillium"}
                            value={penicilliumKrat1}
                            onChange={penicilliumKrat1Change}
                        /></div>
                        <div className="penicillium__krat-2 form-grid__input"><input
                            type={"number"}
                            placeholder={"Penicillium"}
                            value={penicilliumKrat2}
                            onChange={penicilliumKrat2Change}
                        /></div>
                        <div className="penicillium__krat-3 form-grid__input"><input
                            type={"number"}
                            placeholder={"Penicillium"}
                            value={penicilliumKrat3}
                            onChange={penicilliumKrat3Change}
                        /></div>
                        <div className="penicillium__gem form-grid__input"><input
                            type={"number"}
                            value={calculatePenicilliumGem()}
                            readOnly={true}
                        /></div>

                        {/* Mijten */}
                        <div className="mijten__krat-1 form-grid__input"><input
                            type={"number"}
                            value={mijtenKrat1}
                            placeholder={"Mijten"}
                            onChange={mijtenKrat1Change}
                        /></div>
                        <div className="mijten__krat-2 form-grid__input"><input
                            type={"number"}
                            value={mijtenKrat2}
                            placeholder={"Mijten"}
                            onChange={mijtenKrat2Change}
                        /></div>
                        <div className="mijten__krat-3 form-grid__input"><input
                            type={"number"}
                            value={mijtenKrat3}
                            placeholder={"Mijten"}
                            onChange={mijtenKrat3Change}
                        /></div>
                        <div className="mijten__gem form-grid__input"><input
                            type={"number"}
                            value={calculateMijtenGem()}
                            readOnly={true}/>
                        </div>

                        {/* fusarium */}
                        <div className="fusarium__krat-1 form-grid__input"><input
                            type={"number"}
                            value={fusariumKrat1}
                            placeholder={"Fusarium"}
                            onChange={fusariumKrat1Change}
                        /></div>
                        <div className="fusarium__krat-2 form-grid__input"><input
                            type={"number"}
                            value={fusariumKrat2}
                            placeholder={"Fusarium"}
                            onChange={fusariumKrat2Change}
                        /></div>
                        <div className="fusarium__krat-3 form-grid__input"><input
                            type={"number"}
                            value={fusariumKrat3}
                            placeholder={"Fusarium"}
                            onChange={fusariumKrat3Change}
                        /></div>
                        <div className="fusarium__gem form-grid__input"><input
                            type={"number"}
                            readOnly={true}
                            value={calculateFusariumGem()}
                        /></div>

                        {/* STEEL */}
                        <div className="steel__krat-1 form-grid__input"><input
                            type={"number"}
                            value={steelKrat1}
                            placeholder={"Steel"}
                            onChange={steelKrat1Change}
                        /></div>
                        <div className="steel__krat-2 form-grid__input"><input
                            type={"number"}
                            value={steelKrat2}
                            placeholder={"Steel"}
                            onChange={steelKrat2Change}
                        /></div>
                        <div className="steel__krat-3 form-grid__input"><input
                            type={"number"}
                            value={steelKrat3}
                            placeholder={"Steel"}
                            onChange={steelKrat3Change}
                        /></div>
                        <div className="steel__gem form-grid__input"><input
                            type={"number"}
                            readOnly={true}
                            value={calculateSteelGem()}
                        /></div>

                        {/* Spruithoogte */}
                        <div className="spruithoogte__krat-1 form-grid__input">
                            <select>
                                <option disabled selected value>- selecteer spruithoogte -</option>
                                <option value={"A"}>A</option>
                                <option value={"B"}>B</option>
                                <option value={"C"}>C</option>
                                <option value={"D"}>D</option>
                                <option value={"E"}>E</option>
                            </select></div>
                        <div className="spruithoogte__krat-2 form-grid__input"><select>
                            <option disabled selected value>- selecteer spruithoogte -</option>
                            <option value={"A"}>A</option>
                            <option value={"B"}>B</option>
                            <option value={"C"}>C</option>
                            <option value={"D"}>D</option>
                            <option value={"E"}>E</option>
                        </select></div>
                        <div className="spruithoogte__krat-3 form-grid__input"><select>
                            <option disabled selected value>- selecteer spruithoogte -</option>
                            <option value={"A"}>A</option>
                            <option value={"B"}>B</option>
                            <option value={"C"}>C</option>
                            <option value={"D"}>D</option>
                            <option value={"E"}>E</option>
                        </select></div>
                        <div className="spruithoogte__gem form-grid__input"><input
                            value={"n.v.t."}
                            readOnly={true}/></div>

                        {/* waterschade */}
                        <div className="water-schade__krat-1 form-grid__input"><input
                            type={"number"}
                            value={waterschadeKrat1}
                            placeholder={"Waterschade"}
                            onChange={waterschadeKrat1Change}
                        /></div>
                        <div className="water-schade__krat-2 form-grid__input"><input
                            type={"number"}
                            value={waterschadeKrat2}
                            placeholder={"Waterschade"}
                            onChange={waterschadeKrat2Change}
                        /></div>
                        <div className="water-scahde__krat-3 form-grid__input"><input
                            type={"number"}
                            value={waterschadeKrat3}
                            placeholder={"Waterschade"}
                            onChange={waterschadeKrat3Change}
                        /></div>
                        <div className="water-schade__gem form-grid__input"><input
                            type={"number"}
                            readOnly={true}
                            value={calculateWaterschadeGem()}
                        /></div>

                        {/* beworteld */}
                        <div className="bewort__krat-1 form-grid__input"><select>
                            <option disabled selected value>-- Selecteer Beworteld --</option>
                            <option value={"Slecht"}>Slecht</option>
                            <option value={"Matig"}>Matig</option>
                            <option value={"Goed"}>Goed</option>
                            <option value={"Best"}>Best</option>
                        </select></div>
                        <div className="bewort__krat-2 form-grid__input"><select>
                            <option disabled selected value>-- Selecteer Beworteld --</option>
                            <option value={"Slecht"}>Slecht</option>
                            <option value={"Matig"}>Matig</option>
                            <option value={"Goed"}>Goed</option>
                            <option value={"Best"}>Best</option>
                        </select></div>
                        <div className="bewort__krat-3 form-grid__input"><select>
                            <option disabled selected value>-- Selecteer Beworteld --</option>
                            <option value={"Slecht"}>Slecht</option>
                            <option value={"Matig"}>Matig</option>
                            <option value={"Goed"}>Goed</option>
                            <option value={"Best"}>Best</option>
                        </select></div>
                        <div className="bewort__gem form-grid__input"><input
                            readOnly={true}
                            value={"n.v.t."}
                        /></div>

                        {/* DubbelNs */}
                        <div className="dubbel-ns__krat-1 form-grid__input"><input
                            type={"number"}
                            value={dubbelNsKrat1}
                            onChange={dubbelNsKrat1Change}
                            placeholder={"DubbelNs"}
                        /></div>
                        <div className="dubbel-ns__krat-2 form-grid__input"><input
                            type={"number"}
                            value={dubbelNsKrat2}
                            onChange={dubbelNsKrat2Change}
                            placeholder={"DubbelNs"}
                        /></div>
                        <div className="dubbel-ns__krat-3 form-grid__input"><input
                            type={"number"}
                            value={dubbelNsKrat3}
                            onChange={dubbelNsKrat3Change}
                            placeholder={"DubbelNs"}
                        /></div>
                        <div className="dubbel-ns__gem form-grid__input"><input
                            readOnly={true}
                            type={"number"}
                            value={calculateDubbelNsGem()}
                        /></div>

                        {/* gewicht */}
                        <div className="gewicht__krat-1 form-grid__input"><input
                            type={"number"}
                            value={gewichtKrat1}
                            onChange={gewichtKrat1Change}
                            placeholder={"Gewicht"}
                        /></div>
                        <div className="gewicht__krat-2 form-grid__input"><input
                            type={"number"}
                            value={gewichtKrat2}
                            onChange={gewichtKrat2Change}
                            placeholder={"Gewicht"}
                        /></div>
                        <div className="gewicht__krat-3 form-grid__input"><input
                            type={"number"}
                            value={gewichtKrat3}
                            onChange={gewichtKrat3Change}
                            placeholder={"Gewicht"}
                        /></div>
                        <div className="gewicht__gem form-grid__input"><input
                            type={"number"}
                            readOnly={true}
                            value={calculateGewichtGem()}
                        /></div>

                        <div className="zwarte-spruit__krat-1 form-grid__input"><input
                            type={"number"}
                            value={zwarteSpruitKrat1}
                            onChange={zwarteSpruitKrat1Change}
                            placeholder={"Zwarte Spruit"}
                        /></div>
                        <div className="zwarte-spruit__krat-2 form-grid__input"><input
                            type={"number"}
                            value={zwarteSpruitKrat2}
                            onChange={zwarteSpruitKrat2Change}
                            placeholder={"Zwarte Spruit"}
                        /></div>
                        <div className="zwarte-spruit__krat-3 form-grid__input"><input
                            type={"number"}
                            value={zwarteSpruitKrat3}
                            onChange={zwarteSpruitKrat3Change}
                            placeholder={"Zwarte Spruit"}
                        /></div>
                        <div className="zwarte-spruit__gem form-grid__input"><input
                            readOnly={true}
                            type={"number"}
                            value={calculateZwarteSpruitGem()}
                        /></div>

                        {/* droge stof */}
                        <div className="droge-stof__input form-grid__input"><input
                            type={"text"}
                            placeholder={"Droge Stof"}
                            value={drogeStof}
                            onChange={drogestofChange}
                        /></div>
                    </div>
                </div>

                <div className={"form-onderzoek__section-right"}>
                    <div className={"form-column__data"}>
                        <div className={"form-set__column"}>
                            <label>Datum Keuring</label>
                            <input
                                type={"date"}
                                value={datumKeuring}
                                onChange={datumKeuringChange}
                            />
                        </div>
                        <div className={"form-set__column"}>
                            <label>Soort</label>
                            <input
                                placeholder={"Soort"}
                                value={soort}
                                onChange={soortChange}
                            />
                        </div>
                        <div className={"form-set__column"}>
                            <label>Maat</label>
                            <input
                                placeholder={"Maat"}
                                value={maat}
                                onChange={maatChange}
                            />
                        </div>
                        <div className={"form-set__column"}>
                            <label>Oogstjaar</label>
                            <input
                                type={"number"}
                                placeholder={"Oogstjaar"}
                                value={oogstjaar}
                                onChange={oogstjaarChange}
                            />
                        </div>
                        <div className={"form-set__column"}>
                            <label>Koopbrief</label>
                            <input
                                placeholder={"Koopbrief"}
                                value={koopbrief}
                                onChange={koopbriefChange}
                            />
                        </div>
                        <div className={"form-set__column"}>
                            <label>Vrachtbrief</label>
                            <input
                                value={vrachtbrief}
                                onChange={vrachtbriefChange}
                                placeholder={"Vrachtbrief"}
                            />
                        </div>
                        <div className={"form-set__column"}>
                            <label>Ontvangst Datum</label>
                            <input
                                value={ontvangstDatum}
                                onChange={ontvangstDatumChange}
                                type={"date"}
                            />
                        </div>
                        <div className={"form-set__column"}>
                            <label>Laad Datum</label>
                            <input
                                value={laadDatum}
                                onChange={laadDatumChange}
                                type={"date"}
                            />
                        </div>
                        <div className={"form-set__column"}>
                            <label>Keuring</label>
                            <input
                                value={keuring}
                                onChange={keuringChange}
                                placeholder={"Keuring"}
                            />
                        </div>
                        <div className={"form-set__column"}>
                            <label>Keurmeester</label>
                            <select onChange={keurmeesterChange} value={keurmeester}>
                                <option disabled selected value>-- selecteer keurder --</option>
                                <option>Majel Langedijk</option>
                            </select>
                        </div>
                        <div className={"form-set__column"}>
                            <label>Aantal Stuks</label>
                            <input
                                type={"number"}
                                value={aantalStuks}
                                onChange={aantalStuksChange}
                                placeholder={"Aantal Stuks"}
                            />
                        </div>
                        <div className={"form-set__column"}>
                            <label>Aantal Kratten</label>
                            <input
                                value={aantalKratten}
                                onChange={aantalKrattenChange}
                                type={"number"}
                                placeholder={"Aantal Kratten"}
                            />
                        </div>
                        <div className={"form-set__column"}>
                            <label>Aantal Per Krat</label>
                            <input
                                value={aantalPerKrat}
                                onChange={aantalPerKratChange}
                                type={"number"}
                                placeholder={"Aantal Per Krat"}
                            />
                        </div>
                        <div className={"form-set__column"}>
                            <label>Herkomst</label>
                            <input
                                value={herkomst}
                                onChange={herkomstChange}
                                placeholder={"Herkomst"}
                            />
                        </div>
                        <div className={"form-set__column"}>
                            <label>Prev.ontsm</label>
                            <input
                                value={prevOntsm}
                                onChange={prevOntsmChange}
                                placeholder={"Prev.ontsm"}
                            />
                        </div>
                        <div className={"form-set__column"}>
                            <label>Indruk</label>
                            <select onChange={indrukChange} value={indruk}>
                                <option disabled selected value>-- Indruk --</option>
                                <option value={"Slecht"}>Slecht</option>
                                <option value={"Normaal"}>Normaal</option>
                                <option value={"Goed"}>Goed</option>
                                <option value={"Best"}>Best</option>
                            </select>
                        </div>
                        <div className={"form-set__column"}>
                            <label>Gespoeld</label>
                            <select onChange={gespoeldChange} value={gespoeld}>
                                <option disabled selected value>-- Gespoeld --</option>
                                <option value={1}>Ja</option>
                                <option value={0}>Nee</option>
                            </select>
                        </div>
                        <div className={"form-set__column"}>
                            <label>Behouden</label>
                            <select onChange={behoudenChange} value={behouden}>
                                <option disabled selected value>-- Behouden --</option>
                                <option value={1}>Ja</option>
                                <option value={0}>Nee</option>
                            </select>
                        </div>
                        <div className={"form-set__column"}>
                            <label>Foto gemaakt</label>
                            <select onChange={fotoChange} value={foto}>
                                <option disabled selected value>-- Foto gemaakt --</option>
                                <option value={1}>Ja</option>
                                <option value={0}>Nee</option>
                            </select>
                            <input type={"file"} onChange={fileSelecterHandler}/>
                            <button>FOTO OPSLAAN</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"form-opmerkingen"}>
                <label>Opmerkingen</label>
                <textarea name="" id="opmerkingen-input" cols="150" rows="3" value={opmerking} onChange={opmerkingChange}></textarea>
            </div>
            <button className={"button__bloem-aanmaken"} onClick={onderzoekNaarDb}>Opslaan</button>
        </form>
    )
}
export default OnderzoeksFormulier