import Modal from "../../../UI/Modal";
import "./VoegKlantToe.css"
import CloseButtonModal from "../../../UI/CloseButtonModal";
import {Link} from "react-router-dom";
import {useState} from "react";
import Axios from "axios";
const VoegKlantToe = props => {
    const [bedrijfValue, setBedrijfValue] = useState("")
    const [contactpersoonValue, setContactpersoonValue] = useState("")
    const [landValue, setLandValue] = useState("")
    const [stadValue, setStadValue] = useState("")
    const [adresValue, setAdresValue] = useState("")
    const [functieValue, setFunctieValue] = useState("")
    const [emailValue, setEmailValue] = useState("")
    const [telValue, setTelValue] = useState("")
    const [kvkValue, setKvkValue] = useState(0)
    const [wechatValue, setWechatValue] = useState("")
    const bedrijfValueHandler = e => {
        setBedrijfValue(e.target.value)
    }
    const contactValueHandler = e => {
        setContactpersoonValue(e.target.value)
    }
    const landValueHandler = e => {
        setLandValue(e.target.value)
    }
    const stadValueHandler = e => {
        setStadValue(e.target.value)
    }
    const adresValueHandler = e => {
        setAdresValue(e.target.value)
    }
    const functieValueHandler = e => {
        setFunctieValue(e.target.value)
    }
    const emailValueHandler = e => {
        setEmailValue(e.target.value)
    }
    const telValueHandler = e => {
        setTelValue(e.target.value)
    }
    const kvkValueHandler = e => {
        setKvkValue(e.target.value)
    }
    const wechatValueHandler = e => {
        setWechatValue(e.target.value)
    }
    const saveClient = e => {
        e.preventDefault()

        Axios.post("/nieuweKlant", {
            bedrijf: bedrijfValue,
            contactpersoon: contactpersoonValue,
            land: landValue,
            stad: stadValue,
            adres: adresValue,
            functie: functieValue,
            email: emailValue,
            tel: telValue,
            kvk: kvkValue,
            wechat: wechatValue
        })
        window.open("/klanten", "_self")
    }

    return (
        <Modal>
            <Link to={'/klanten'}>
                <CloseButtonModal/>
            </Link>
            <div className={"voeg-klant-toe__popup"}>
                <form method={"POST"}>
                    <div className={"form-set__modal"}>
                        <label>Bedrijfsnaam:</label>
                        <input id={"input-company"} name={"bedrijf"} value={bedrijfValue} onChange={bedrijfValueHandler}/>
                    </div>
                    <div className={"form-set__modal"}>
                        <label>Contactpersoon:</label>
                        <input id={"input-contactPerson"} name={"contactpersoon"} value={contactpersoonValue} onChange={contactValueHandler}/>
                    </div>
                    <div className={"form-set__modal"}>
                        <label>Land:</label>
                        <input id={"input-country"} name={"land"} value={landValue} onChange={landValueHandler}/>
                    </div>
                    <div className={"form-set__modal"}>
                        <label>Stad:</label>
                        <input id={"input-city"} name={"stad"} value={stadValue} onChange={stadValueHandler}/>
                    </div>
                    <div className={"form-set__modal"}>
                        <label>Adres:</label>
                        <input id={"input-address"} name={"adres"} value={adresValue} onChange={adresValueHandler}/>
                    </div>
                    <div className={"form-set__modal"}>
                        <label>Functie:</label>
                        <input id={"input-function"} name={"functie"} value={functieValue} onChange={functieValueHandler}/>
                    </div>
                    <div className={"form-set__modal"}>
                        <label>E-mail:</label>
                        <input type={"email"} id={"input-email"} name={"email"} value={emailValue} onChange={emailValueHandler}/>
                    </div>
                    <div className={"form-set__modal"}>
                        <label>Telefoonnummer:</label>
                        <input type={"tel"} id={"input-tel"} name={"tel"} value={telValue} onChange={telValueHandler}/>
                    </div>
                    <div className={"form-set__modal"}>
                        <label>KVK:</label>
                        <input type={"number"} id={"input-kvk"} name={"kvk"} value={kvkValue} onChange={kvkValueHandler}/>
                    </div>
                    <div className={"form-set__modal"}>
                        <label>WeChat:</label>
                        <input id={"input-wechat"} name={"wechat"} value={wechatValue} onChange={wechatValueHandler}/>
                    </div>
                    <button type={"submit"} id={"opslaan"} onClick={saveClient}>Opslaan</button>
                </form>
            </div>
        </Modal>
    )
}



export default VoegKlantToe