import React from "react";

const Boekhouden = () => {
    return (
        <div className={"base-container"}>

        </div>
    )
}

export default Boekhouden